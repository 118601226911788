import React from 'react';
import { Link } from 'gatsby';

const Footer = ({ site }) => {
  return (
    <div className="nav-footer">
      <nav className="nav-wrapper">
        <span className="nav-copy">God Can. God Cares. © 2020</span>
      </nav>
    </div>
  );
};

export default Footer;
