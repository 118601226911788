import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const logo = (data, isHome) => {
  if (!isHome && data.logo) {
    return (
      <span className="logo">
        <a href="/" title="Home">
          <img src={data.logo} />
        </a>
      </span>
    );
  }
};

const getNavClass = navItem => {
  switch (navItem) {
    case 'Home':
      return 'nav-home';
      break;
    case 'Tag':
      return 'nav-tag';
      break;
    case 'Author':
      return 'nav-author';
      break;
    case 'Help':
      return 'nav-help';
      break;
  }
};

const getNavigation = (nav, isHome) => {
  if (isHome) {
    return nav.filter(item => {
      return item.label !== 'Home';
    });
  }
  return nav;
};

const toggleMenu = () => {
  document.documentElement.classList.toggle('menu-active');
};

/**
 * Navigation component
 *
 * The Navigation component takes an array of your Ghost
 * navigation property that is fetched from the settings.
 * It differentiates between absolute (external) and relative link (internal).
 * You can pass it a custom class for your own styles, but it will always fallback
 * to a `site-nav-item` class.
 *
 */
const Navigation = ({ data, navClass, isHome }) => (
  <>
    <div className="nav-header">
      <nav className="nav-wrapper">
        {logo(data, isHome)}
        <ul role="navigation" aria-label="Navigation">
          {getNavigation(data.navigation, isHome).map((navItem, i) => {
            if (navItem.url.match(/^\s?http(s?)/gi)) {
              return (
                <li
                  key={i}
                  className={getNavClass(navItem.label)}
                  role="presentation"
                >
                  <a
                    className={navClass}
                    href={navItem.url}
                    key={i}
                    rel="noopener noreferrer"
                  >
                    {navItem.label}
                  </a>
                </li>
              );
            } else {
              return (
                <li
                  key={i}
                  className={getNavClass(navItem.label)}
                  role="presentation"
                >
                  <Link
                    className={navClass}
                    to={navItem.url}
                    activeClassName={'active'}
                    key={i}
                  >
                    {navItem.label}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </nav>
      <div className="nav-wrapper-control" onClick={toggleMenu}>
        <div className="inner">
          <a className="nav-menu">
            <i className="icon icon-menu"></i>Menu
          </a>
          {logo(data, isHome)}
        </div>
      </div>
    </div>
  </>
);

export default Navigation;
