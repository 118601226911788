import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Tags } from '@tryghost/helpers-gatsby';
import { readingTime as readingTimeHelper } from '@tryghost/helpers';

const PostCard = ({ post }) => {
  const url = `/${post.slug}/`;
  const readingTime = readingTimeHelper(post);

  return (
    <article className="post">
      <div className="inner">
        <div className="box post-box">
          <h2 className="post-title">
            <Link to={url}>{post.title}</Link>
          </h2>

          <span className="post-meta">
            By <a href="/author/ghost/">{post.primary_author.name}</a> on{' '}
            <time dateTime={post.published_at}>{post.published_at_pretty}</time>
          </span>

          <p className="post-excerpt">{post.excerpt}</p>
          <div style={{ textAlign: 'right' }}>
            <Link to={url} className="read-more">
              Read more.
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
};

PostCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    featured: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    excerpt: PropTypes.string.isRequired,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PostCard;
