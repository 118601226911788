import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Pagination = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages,
  } = pageContext;

  return (
    <nav className="pagination" role="navigation">
      <div className="inner">
        <div className="box pagination-box" role="navigation">
          {previousPagePath && (
            <Link
              title="Newer Posts"
              className="pagination-next"
              rel="prev"
              to={previousPagePath}
            >
              <i className="icon icon-arrow-left"></i>{' '}
              <span className="pagination-label">Newer Posts</span>
            </Link>
          )}
          {numberOfPages > 1 && (
            <span className="pagination-info">
              Page {humanPageNumber} of {numberOfPages}
            </span>
          )}
          {nextPagePath && (
            <Link
              title="Older Posts"
              className="pagination-prev"
              rel="next"
              to={nextPagePath}
            >
              <span className="pagination-label">Older Posts</span>{' '}
              <i className="icon icon-arrow-right"></i>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Pagination;
